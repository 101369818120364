.menusidebar {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: black;
    flex-basis: 100px;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.menusidebar > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.menusidebar > div:nth-child(2) {
    transform: rotate(-90deg);
    position: absolute;
    bottom: 100px;
}

.menusidebar > div:nth-child(2) > p {
    color: white;
    margin: 0;
    width: 200px;
}


@media (max-width: 1635px) {
    .menusidebar {
        padding: 10px;
    }

    .menusidebar > div:nth-child(1) {
        flex-direction: row;
        justify-content: space-between;
        width: 600px;
    }

    .menusidebar > div:nth-child(2) {
        transform: inherit;
        right: 0;
        bottom: 10px;
    }
}