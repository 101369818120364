.home {
    display: flex;
    flex-direction: row;
    height: 90%;
    max-height: 90%;
}

.home-dashboard {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 25px 75px;
    gap: 20px;
    justify-content: center;
}

.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-dashboard > h1, .home-dashboard > p {
    margin: 0;
}

.home-dashboard-stats {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    gap: 20px;
}

.home-dashboard-stats-charts {
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.home-dashboard-stats-charts_bar {
    border-radius: 10px;
    background-color: #eeeeee;
    flex: 1;
}

.home-dashboard-stats-charts-cards {
    display: flex;
    gap: 10px;
    flex: 1;
}

.home-dashboard-stats-charts-cards > div {
    border-radius: 10px;
    flex: 1;
}

.home-dashboard-stats-cards {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    gap: 15px;
}

.home-dashboard-stats-charts-cards_radar {
    background-color: #282D30;
}

.home-dashboard-stats-charts-cards_radial {
    background-color: #eeeeee;
}

.home-dashboard-stats-charts-cards_linear {
    background-color: #ff0000;
    position: relative;
}

@media (max-width: 1635px) {

    .home{
        height: 100%;
        max-height: 100%;
        flex-direction: column;
    }

    .home-dashboard-stats {
        flex-direction: column;
    }

    .home-dashboard-stats-charts-cards {
        flex-direction: column;
    }

    .home-dashboard-stats-cards {
        flex-direction: row;
    }
}