.header {
    background-color: black;
    display: flex;
    align-items: center;
    height: 10%;
}

.header > div:nth-child(2) {
    width: 100%;
    margin: 0 100px;
}

.header-logo {
    width: 200px;
    margin: 20px;
}

.header-nav {
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.header-nav > a {
    color: white;
    text-decoration: inherit;
    font-size: x-large
}

@media (max-width: 1635px) {
    .header-logo {
        width: 150px;
        margin: 20px;
    }
}